/**
 * Created by alex on 28-7-2017.
 */

import { GridDataRow, GridRule }                   from '../models';
import { isFunction, isNullOrUndefined }           from '@cs/core';
import { Logger }                                  from '@cs/components/util';
import { DataGridCellType, GridActions, RowState } from '../enums/data-grid.enum';
import {
	DataGridCssAction,
	DataGridRemoveCssAction,
	DataGridFormatAction,
	DataGridLookupAction,
	DataGridStateAction,
	DataGridChangeTextAction,
	DataGridDataGridSortItemAction,
	DataGridUiStateAction,
	DataGridBehaviorAction, DataGridSetVirtualKeysAction
} from '../classes/data-grid-action';
import { DataGridElementFactory }                  from './data-grid-element.factory';
import {
  DataGridItemState,
  DataGridSheetSettingsAction,
  DataGridCalculationOptionsAction,
  RowButton,
  DataGridValidatorAction,
  DataGridRuleMatch
}                                                  from '../classes';

export class DataGridParser {
  static parseRules(rules: Array<any>) {
    const output: Array<GridRule> = [];
    for (const rule of rules) {
      let parsedMatch   = this.parseMatch(rule);
      let parsedActions = this.parseRuleActions(rule);
      const cellTypes   = isNullOrUndefined(rule.cellType) ? [DataGridCellType.Data] : rule.cellType.split(',');
      const rowTypes    = isNullOrUndefined(rule.rowType) ? [RowState.Default] : rule.rowType.split(',');
      for (const rowType of rowTypes) {
        for (const cellType of cellTypes) {
          let paresRule = new GridRule(
            {
              target:      rule.target,
              match:       parsedMatch,
              actions:     parsedActions,
              description: rule.description,
              cellType:    cellType,
              rowType:     rowType
            }
          );
          output.push(paresRule);
        }
      }
    }
    return output;
  }

  static parseMatch(rule): DataGridRuleMatch {
    let match = rule.match;

    if (!match.hasOwnProperty('levelKeys')) {
      Logger.Warning(`${JSON.stringify(rule)} old match rules, please change this to the nested variant`);
      // Set match all as work around
      match = null;
    }

    return new DataGridRuleMatch(match);
  }

  static parseRuleActions(rule) {
    const actions     = rule.actions;
    const parsedActions = [];
    for (const action of actions) {
      let parsedAction;
      switch (action.type) {
        case GridActions.SetState:
          if (isNullOrUndefined(action.state)) {
            Logger.Warning(`${JSON.stringify(action)} .state not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridStateAction({
            state: new DataGridItemState(action.state)
          });
          break;
        case GridActions.SetUiState:
          if (isNullOrUndefined(action.state)) {
            Logger.Warning(`${JSON.stringify(action)} .state not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridUiStateAction({state: action.state});
          break;
        case GridActions.SetBehavior:
          if (isNullOrUndefined(action.action)) {
            Logger.Warning(`${JSON.stringify(action)} .action not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridBehaviorAction({
            action: action.action,
            params: action.params
          });
          break;
        case GridActions.ChangeCss:
          if (isNullOrUndefined(action.classes)) {
            Logger.Warning(`${JSON.stringify(action)} .classes not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridCssAction({classes: action.classes});
          break;
        case GridActions.RemoveCss:
          if (isNullOrUndefined(action.classes)) {
            Logger.Warning(`${JSON.stringify(action)} .classes not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridRemoveCssAction({classes: action.classes});
          break;
        case GridActions.SetLookup:
          if (isNullOrUndefined(action)) {
            Logger.Warning(`${JSON.stringify(action)} .lookup not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridLookupAction(action);
          break;
        case GridActions.SetFormatting:
          if (isNullOrUndefined(action.format)) {
            Logger.Warning(`${JSON.stringify(action)} .format not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridFormatAction({
            format: action.format
          });
          break;
        case GridActions.SetSheetSettings:
          if (isNullOrUndefined(action.settings)) {
            Logger.Warning(`${JSON.stringify(action)} .settings not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridSheetSettingsAction(action);
          break;
        case GridActions.SetCalculationOptions:
          if (isNullOrUndefined(action)) {
            Logger.Warning(`${JSON.stringify(action)} .setCalculationOptions not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridCalculationOptionsAction(action);
          break;
        case GridActions.AddValidator:
          if (isNullOrUndefined(action)) {
            Logger.Warning(`${JSON.stringify(action)} .addValidator not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridValidatorAction(action);
          break;
        case GridActions.TextChange:
          if (isNullOrUndefined(action)) {
            Logger.Warning(`${JSON.stringify(action)} .action not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridChangeTextAction(action);
          break;
        case GridActions.SetDataGridSortItem:
          if (isNullOrUndefined(action)) {
            Logger.Warning(`${JSON.stringify(action)} .action not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridDataGridSortItemAction(action);
          break;
								case GridActions.SetVirtualKeys:
          if (isNullOrUndefined(action)) {
            Logger.Warning(`${JSON.stringify(action)} .action not found`);
            Logger.Warning(rule);
            continue;
          }
          parsedAction = new DataGridSetVirtualKeysAction(action);
          break;
      }
      if (!isNullOrUndefined(parsedAction)) {
        parsedActions.push(parsedAction);
      }

    }
    return parsedActions;
  }

  static parseInjectColumns(injectColumns: any[]) {
    const output = [];
    if (isNullOrUndefined(injectColumns)) {
      return output;
    }

    try {
      injectColumns.forEach(x => output.push(DataGridElementFactory.createGridHeaderCell(x)));
    } catch (ex) {
      Logger.Warning(`The parsing of the injected columns went wrong.`);
      Logger.Warning(injectColumns);
    }
    return output;
  }

  static parseRowButtons(rowButtons: any[], disabledFunctions: Map<string, (row: GridDataRow) => boolean>) {
    let output = [];
    if (isNullOrUndefined(rowButtons)) {
      return null;
    }

    try {
      output = rowButtons.map(btn => {
        const rowBtn     = new RowButton(btn.label, btn.name, btn.description, DataGridParser.getFunction(btn.disabled, disabledFunctions));
        rowBtn.iconClass = btn.iconClass;
        return rowBtn;
      });
    } catch (ex) {
      Logger.Warning(`The parsing of the RowButtons went wrong.`);
      Logger.Warning(rowButtons);
    }
    return output;
  }

  static getFunction(disabledFunction: string, disabledFunctions: Map<string, (row: GridDataRow) => boolean>): (row: GridDataRow) => boolean {

    let func = (row) => false;

    if (isFunction(disabledFunction))
      return disabledFunction as any;

    if (disabledFunctions.size > 0 && disabledFunctions.has(disabledFunction)) {
      func = disabledFunctions.get(disabledFunction);
    }

    return func;
  }
}
