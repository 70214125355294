import { LoggerUtil }                    from './logger.util';
import { createObjectWithLowerCaseKeys } from './object.utils';

/**
 *  Replace the placeholders like: :idCustomer and provide 'idCustomer' as the value as the first group
 */
export const PlaceholderRegexPathUrlVariable = /:(.\w+)/g;

/**
 * Replace the placeholders like: ${idCustomer} and provide 'idCustomer' as the value as the first group
 */
export const PlaceholderRegexStringInterpolation = /\$\{([^}]+)\}/g;

export function toCamelCase(str: string) {
	return str.charAt(0)
			  .toLowerCase() + str.slice(1);
}

export function toKebabCase(str: string) {
	return str.replace(/([a-z])([A-Z])/g, '$1-$2')
			  .toLowerCase();
}

export function isBase64(v, opts) {
	if (v instanceof Boolean || typeof v === 'boolean') {
		return false;
	}

	if (!(opts instanceof Object)) {
		opts = {};
	}

	if (opts.allowEmpty === false && v === '') {
		return false;
	}

	let regex       = '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+\/]{3}=)?';
	const mimeRegex = '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)';

	if (opts.mimeRequired === true) {
		regex = mimeRegex + regex;
	} else if (opts.allowMime === true) {
		regex = mimeRegex + '?' + regex;
	}

	if (opts.paddingRequired === false) {
		regex = '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}(==)?|[A-Za-z0-9+\\/]{3}=?)?';
	}

	return (new RegExp('^' + regex + '$', 'gi')).test(v);
}


/**
 * Replace placeholders in a string with the values found in the replacement object.
 * @param replacementObject The object {[placeholdername: string] : value}
 * @param stringToReplace String that should contain the placeholders
 * @param placeholderRegex The regular expression that returns for the full match ( ${idCustomer} )
 * and the first group containing the placeholderName ( idCustomer )
 */
export function replacePlaceholders(replacementObject        = null, stringToReplace: string,
									placeholderRegex: RegExp = PlaceholderRegexStringInterpolation) {

	const replacements = createObjectWithLowerCaseKeys(replacementObject);
	if (replacements) {
		const replaceRegex = placeholderRegex;
		let replaceMatch   = replaceRegex.exec(stringToReplace);
		while (replaceMatch != null) {
			const replaceParam = replaceMatch[0];
			const replacement  = replacements[replaceMatch[1].toLowerCase()];
			if (replacement != null) {
				stringToReplace = stringToReplace.replace(replaceParam, replacement);
			} else {
				LoggerUtil.warn(`${replacement} not found`);
			}
			replaceMatch = replaceRegex.exec(stringToReplace);
		}
	}
	return stringToReplace;
}

/**
 * Copies a given text to the clipboard.
 * First tries to use the Clipboard API, which is the modern approach.
 * Falls back to a more traditional method using a temporary textarea if the Clipboard API is not available or fails.
 *
 * @param text The text to be copied to the clipboard.
 * @returns A promise that resolves to a boolean indicating the success or failure of the copy operation.
 */
export function copyTextToClipboard(text: string): Promise<boolean> {
	// Check if the Clipboard API is available
	if (navigator.clipboard && navigator.clipboard.writeText) {
		return navigator.clipboard.writeText(text)
						.then(() => true)
						.catch((err) => {
							console.error('Clipboard API copy failed:', err);
							return useFallbackCopyMethod(text);
						});
	} else {
		// Use the fallback method if Clipboard API is not available
		return this.useFallbackCopyMethod(text);
	}
}

/**
 * Fallback method to copy text to clipboard.
 * Creates a temporary textarea element to hold and select the text, then executes the copy command.
 *
 * @param text The text to be copied.
 * @returns A promise that resolves to a boolean indicating the success or failure of the operation.
 */
function useFallbackCopyMethod(text: string): Promise<boolean> {
	return new Promise((resolve) => {
		// Create a temporary textarea element
		const textArea = document.createElement('textarea');
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.select();

		try {
			// Execute the copy command
			const successful = document.execCommand('copy');
			resolve(successful);
		} catch (err) {
			console.error('Fallback copy method failed:', err);
			resolve(false);
		} finally {
			// Clean up by removing the textarea
			document.body.removeChild(textArea);
		}
	});
}
