/**
 * SATS | Cohelion
 * API documentation for SATS | Cohelion
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
  HttpResponse,
  HttpEvent
}                                                            from '@angular/common/http';
import { CsHttpClient, CsHttpRequestOptions }                from '@cs/core';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable } from 'rxjs';

import { ActionButtonResult }                                     from '../model/actionButtonResult';
import { AddMemberDialog }                                     from '../model/addMemberDialog';
import { Config }                                     from '../model/config';
import { DashboardResponse }                                     from '../model/dashboardResponse';
import { ImportResultMessage }                                     from '../model/importResultMessage';
import { InitBundle }                                     from '../model/initBundle';
import { StructureData }                                     from '../model/structureData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DataentrygridsService {

    protected basePath = ''; // No default basepath
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: CsHttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Get dashboard data
     * @param params Params object as JSON string
     * @param selection Selection object as JSON string
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsDashboardPost(params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<DashboardResponse>;
    public dataentrygridsDashboardPost(params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardResponse>>;
    public dataentrygridsDashboardPost(params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardResponse>>;
    public dataentrygridsDashboardPost(params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<DashboardResponse>(`${this.basePath}/dataentrygrids/dashboard`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get an aggregation over the facts data.
     * Get an aggregation over the facts data.
     * @param name Grid name
     * @param params Params object as JSON struct
     * @param levelKeys Level key array as JSON struct, for example: [\&quot;idMonth\&quot;, \&quot;idOffice\&quot;] Any unmentioned levels will be aggregated. Accepts both level names and keynames.
     * @param selection Selection object as JSON struct
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameAggregationPost(name: string, params?: string, levelKeys?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dataentrygridsNameAggregationPost(name: string, params?: string, levelKeys?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dataentrygridsNameAggregationPost(name: string, params?: string, levelKeys?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dataentrygridsNameAggregationPost(name: string, params?: string, levelKeys?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameAggregationPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (levelKeys !== undefined) {
            formParams = formParams.append('levelKeys', <any>levelKeys) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/aggregation`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Trigger action button , returns ActionButtonResult
     * Trigger action button , returns ActionButtonResult
     * @param name Grid name
     * @param buttonName Button name
     * @param params Params object for grid
     * @param selection Selection object for grid
     * @param payload Payload object from button
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameButtonButtonNamePost(name: string, buttonName: string, params?: string, selection?: string, payload?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<ActionButtonResult>;
    public dataentrygridsNameButtonButtonNamePost(name: string, buttonName: string, params?: string, selection?: string, payload?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ActionButtonResult>>;
    public dataentrygridsNameButtonButtonNamePost(name: string, buttonName: string, params?: string, selection?: string, payload?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ActionButtonResult>>;
    public dataentrygridsNameButtonButtonNamePost(name: string, buttonName: string, params?: string, selection?: string, payload?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameButtonButtonNamePost.');
        }
        if (buttonName === null || buttonName === undefined) {
            throw new Error('Required parameter buttonName was null or undefined when calling dataentrygridsNameButtonButtonNamePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }
        if (payload !== undefined) {
            formParams = formParams.append('payload', <any>payload) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<ActionButtonResult>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/button/${encodeURIComponent(String(buttonName))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get the grid config
     * Get the grid config
     * @param name Grid name
     * @param params Params object as JSON string
     * @param selection Selection object as JSON string
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameConfigPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Config>;
    public dataentrygridsNameConfigPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Config>>;
    public dataentrygridsNameConfigPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Config>>;
    public dataentrygridsNameConfigPost(name: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameConfigPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<Config>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/config`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Confirms facts in the selection.
     * Confirms facts in the selection.
     * @param name Grid name
     * @param params Params object as JSON struct
     * @param selection Selection object as JSON struct
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameConfirmfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dataentrygridsNameConfirmfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dataentrygridsNameConfirmfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dataentrygridsNameConfirmfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameConfirmfactsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/confirmfacts`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get the grid data as Excel export
     * Get the grid data as Excel export
     * @param name Grid name
     * @param exportName Export name
     * @param params Params object
     * @param selection Selection object
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameExportsExportNamePost(name: string, exportName: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public dataentrygridsNameExportsExportNamePost(name: string, exportName: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public dataentrygridsNameExportsExportNamePost(name: string, exportName: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public dataentrygridsNameExportsExportNamePost(name: string, exportName: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameExportsExportNamePost.');
        }
        if (exportName === null || exportName === undefined) {
            throw new Error('Required parameter exportName was null or undefined when calling dataentrygridsNameExportsExportNamePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.ms-excel'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/exports/${encodeURIComponent(String(exportName))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get the Add Member dialog form definition.
     * Get the Add Member dialog form definition.
     * @param name Grid name
     * @param params Params object as JSON string
     * @param selection Selection object as JSON string
     * @param memberList List of selectable members in dialog
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameGetdialogAddmemberPost(name: string, params?: string, selection?: string, memberList?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<AddMemberDialog>;
    public dataentrygridsNameGetdialogAddmemberPost(name: string, params?: string, selection?: string, memberList?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddMemberDialog>>;
    public dataentrygridsNameGetdialogAddmemberPost(name: string, params?: string, selection?: string, memberList?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddMemberDialog>>;
    public dataentrygridsNameGetdialogAddmemberPost(name: string, params?: string, selection?: string, memberList?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameGetdialogAddmemberPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }
        if (memberList !== undefined) {
            formParams = formParams.append('MemberList', <any>memberList) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<AddMemberDialog>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/getdialog/addmember`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get the grid facts data. Returns full facts and meta info. Use /report for aggregations.
     * Get the grid facts data. Returns full facts and meta info. Use /report for aggregations.
     * @param name Grid name
     * @param params Params object as JSON struct
     * @param selection Selection object as JSON struct
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameGetfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dataentrygridsNameGetfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dataentrygridsNameGetfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dataentrygridsNameGetfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameGetfactsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/getfacts`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get the grid data entry thresholds. Returns struct of columns and data.
     * Get the grid data entry thresholds. Returns struct of columns and data.
     * @param name Grid name
     * @param params Params object as JSON struct
     * @param selection Selection object as JSON struct
     * @param aggregatedkeys Keys object as JSON struct. Add ChoiceSets to calculate grid specic &#39;defaults&#39;.
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameGetfactthresholdsPost(name: string, params?: string, selection?: string, aggregatedkeys?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dataentrygridsNameGetfactthresholdsPost(name: string, params?: string, selection?: string, aggregatedkeys?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dataentrygridsNameGetfactthresholdsPost(name: string, params?: string, selection?: string, aggregatedkeys?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dataentrygridsNameGetfactthresholdsPost(name: string, params?: string, selection?: string, aggregatedkeys?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameGetfactthresholdsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }
        if (aggregatedkeys !== undefined) {
            formParams = formParams.append('aggregatedkeys', <any>aggregatedkeys) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/getfactthresholds`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Checks if there are unconfirmed facts in the selection.
     * Checks if there are unconfirmed facts in the selection.
     * @param name Grid name
     * @param params Params object as JSON struct
     * @param selection Selection object as JSON struct
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameHasunconfirmedfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dataentrygridsNameHasunconfirmedfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dataentrygridsNameHasunconfirmedfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dataentrygridsNameHasunconfirmedfactsPost(name: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameHasunconfirmedfactsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/hasunconfirmedfacts`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Excel import
     * Excel import
     * @param name Grid name
     * @param importName Import name
     * @param file Upload file
     * @param params Params object
     * @param selection Selection object. Not used for native import.
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameImportsImportNamePost(name: string, importName: string, file: Blob, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<ImportResultMessage>;
    public dataentrygridsNameImportsImportNamePost(name: string, importName: string, file: Blob, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportResultMessage>>;
    public dataentrygridsNameImportsImportNamePost(name: string, importName: string, file: Blob, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportResultMessage>>;
    public dataentrygridsNameImportsImportNamePost(name: string, importName: string, file: Blob, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameImportsImportNamePost.');
        }
        if (importName === null || importName === undefined) {
            throw new Error('Required parameter importName was null or undefined when calling dataentrygridsNameImportsImportNamePost.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling dataentrygridsNameImportsImportNamePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }
        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<ImportResultMessage>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/imports/${encodeURIComponent(String(importName))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * Get the grid init bundle, which includes config, structure data, filter data and facts
     * @param name Grid name
     * @param params Params object as JSON string
     * @param selection Selection object as JSON string
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameInitbundlePost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<InitBundle>;
    public dataentrygridsNameInitbundlePost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InitBundle>>;
    public dataentrygridsNameInitbundlePost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InitBundle>>;
    public dataentrygridsNameInitbundlePost(name: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameInitbundlePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<InitBundle>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/initbundle`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get row totals over a custom row definition and the column definition of the grid.
     * Get row totals over a custom row definition and the column definition of the grid.
     * @param name Grid name
     * @param params Params object as JSON struct
     * @param levelKeys Level key array as JSON struct, for example: [\&quot;idOffice\&quot;] Any unmentioned levels will be aggregated. Accepts both level names and keynames.
     * @param selection Selection object as JSON struct
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameRowtotalsPost(name: string, params?: string, levelKeys?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dataentrygridsNameRowtotalsPost(name: string, params?: string, levelKeys?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dataentrygridsNameRowtotalsPost(name: string, params?: string, levelKeys?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dataentrygridsNameRowtotalsPost(name: string, params?: string, levelKeys?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameRowtotalsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (levelKeys !== undefined) {
            formParams = formParams.append('levelKeys', <any>levelKeys) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/rowtotals`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Save the grid facts data
     * Save the grid facts data
     * @param name Grid name
     * @param data Data object as JSON string (data:{{columns: [...], data: [[...], [...], ...]}})
     * @param params Params object as JSON string
     * @param reason Audit reason
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameSavefactsPost(name: string, data?: string, params?: string, reason?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public dataentrygridsNameSavefactsPost(name: string, data?: string, params?: string, reason?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public dataentrygridsNameSavefactsPost(name: string, data?: string, params?: string, reason?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public dataentrygridsNameSavefactsPost(name: string, data?: string, params?: string, reason?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameSavefactsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) || formParams;
        }
        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (reason !== undefined) {
            formParams = formParams.append('reason', <any>reason) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/savefacts`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get the grid stats data.
     * Get the grid stats data.
     * @param name Grid name
     * @param params Params object
     * @param selection Selection object
     * @param selectionComparison Selection object, for the comparison
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameStatsPost(name: string, params?: string, selection?: string, selectionComparison?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public dataentrygridsNameStatsPost(name: string, params?: string, selection?: string, selectionComparison?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public dataentrygridsNameStatsPost(name: string, params?: string, selection?: string, selectionComparison?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public dataentrygridsNameStatsPost(name: string, params?: string, selection?: string, selectionComparison?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameStatsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }
        if (selectionComparison !== undefined) {
            formParams = formParams.append('selectionComparison', <any>selectionComparison) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<Array<string>>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/stats`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get the grid structure data
     * Get the grid structure data
     * @param name Grid name
     * @param params Params object as JSON struct
     * @param selection Selection object as JSON struct
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataentrygridsNameStructuredataPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<StructureData>;
    public dataentrygridsNameStructuredataPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StructureData>>;
    public dataentrygridsNameStructuredataPost(name: string, params?: string, selection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StructureData>>;
    public dataentrygridsNameStructuredataPost(name: string, params?: string, selection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling dataentrygridsNameStructuredataPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<StructureData>(`${this.basePath}/dataentrygrids/${encodeURIComponent(String(name))}/structuredata`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

}
