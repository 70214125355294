import { ModuleWithProviders, NgModule, Type }                                   from '@angular/core';
import { CommonModule }                                                          from '@angular/common';
import { FilterAndNavbarShellComponent }                                         from './filter-and-navbar-shell.component';
import { FilterAndNavbarShellConfigService }                                     from './filter-and-navbar-shell-config.service';
import { IConfigService }                                                        from '@cs/performance-manager/shared';
import { CsApplicationSettings }                                                 from '@cs/common';
import { HttpClient }                                                            from '@angular/common/http';
import { CsTranslationLoader }                                                   from '@cs/performance-manager/shared';
import { TranslateLoader, TranslateModule, TranslateService }                    from '@ngx-translate/core';
import { LoggerUtil }                                                            from '@cs/core';
import { RouterModule }                                                          from '@angular/router';
import { CsFilterAndCompareBarModule }                                           from '@cs/components/filter-and-compare-bar';
import { CsBottomButtonBarModule }                                               from '@cs/performance-manager/shared';
import { CsCultureModule, ComponentTranslationLoaderService, CsCultureProvider } from '@cs/common';
import { DynamicButtonsAgent }                                                   from '@cs/performance-manager/shared';
import { DefaultPageBottomButtonsAgent }                                         from '@cs/performance-manager/shared';
import { MatButtonToggleModule }                                                 from '@angular/material/button-toggle';
import { MatTooltipModule }                                                      from '@angular/material/tooltip';

export function filterAndNavbarShellTranslationLoader(client: HttpClient,
													  pmAppSettings: CsApplicationSettings,
													  service: IConfigService,
													  componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
								   client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
			  declarations: [FilterAndNavbarShellComponent],
			  imports:      [
				  CommonModule,
				  RouterModule,
				  CsCultureModule,
				  CsFilterAndCompareBarModule,
				  CsBottomButtonBarModule,
				  MatTooltipModule,
				  TranslateModule.forChild({
											   loader:  {
												   provide:    TranslateLoader,
												   useFactory: filterAndNavbarShellTranslationLoader,
												   deps:       [HttpClient, CsApplicationSettings, FilterAndNavbarShellConfigService,
																ComponentTranslationLoaderService]
											   },
											   isolate: true
										   }),
				  MatButtonToggleModule
			  ]
		  })
export class PmFilterAndNavbarShellModule {
	static forRoot(moduleConfig: Type<FilterAndNavbarShellConfigService>,
				   appSetting: CsApplicationSettings,
				   dynamicPageBottomButtonsBar: Type<DynamicButtonsAgent> = DefaultPageBottomButtonsAgent): ModuleWithProviders<PmFilterAndNavbarShellModule> {
		return {
			ngModule:  PmFilterAndNavbarShellModule,
			providers: [
				{
					provide:  FilterAndNavbarShellConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				},
				{
					provide:  DynamicButtonsAgent,
					useClass: dynamicPageBottomButtonsBar
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(culture.getCulture());
	}
}
